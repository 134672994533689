const headerProgressBar = () => {

    let scrollYMax, progressBarWidth;
    const headerProgressBarELement = document.getElementById('header-progress-bar-element');

    window.addEventListener('scroll', () => {
        //get scrollY max
        scrollYMax = document.body.offsetHeight - window.innerHeight;
        //get percent of scrollYMax and window.pageYOffset attitude
        progressBarWidth = ((window.pageYOffset / scrollYMax) * 100);
        //set bar progressBarWidth in bar style width
        headerProgressBarELement.style.width = `${progressBarWidth}%`;
    });
}

export {headerProgressBar}