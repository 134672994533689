const sectionsSlider = () => {
    const sections = document.querySelectorAll('[data-section]');
    const sectionsBlocks = document.querySelectorAll('[data-sections-block]');
    // C = 2 x PI x R or 2 x 3.14 x 50 = 314px
    const circleRadius = 314;
    const lineHeight = 30;

    //section one
    const sectionOne = () => {
        //elements
        const circleParent = sectionsBlocks[0].querySelector('[data-sections-circe-parent]');
        const lineParent = sectionsBlocks[0].querySelector('[data-sections-line-parent]');
        const circle = sectionsBlocks[0].querySelector('[data-sections-circe]');
        const line = sectionsBlocks[0].querySelector('[data-sections-line]');

        //get circle radius and section height current difference
        const circleDifference = (circleRadius / sections[0].scrollHeight);
        //get stroke width
        const strokeWidth = circleRadius - (window.scrollY * circleDifference);
        //set percent of scrolled page in circle stoke attributes
        circle.setAttribute('stroke-dashoffset', strokeWidth);

        //get line radius and section height current difference
        const lineDifference = (lineHeight / sections[0].scrollHeight);
        //set percent of scrolled page in line height
        const lineWidth = (window.scrollY * lineDifference);
        line.style.height = `${lineWidth}px`;

        //if sroke width <= 0, then diacrivate this circle and line - and active next
        if (strokeWidth <= 0) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[1].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[1].querySelector('[data-sections-line-parent]').classList.add('active');
        } 
    }

    //section two
    const sectionTwo = () => {
        //elements
        const circleParent = sectionsBlocks[1].querySelector('[data-sections-circe-parent]');
        const lineParent = sectionsBlocks[1].querySelector('[data-sections-line-parent]');
        const circle = sectionsBlocks[1].querySelector('[data-sections-circe]');
        const line = sectionsBlocks[1].querySelector('[data-sections-line]');

        //get circle radius and section height current difference
        const circleDifference = (circleRadius / sections[1].scrollHeight);
        //get stroke width
        const strokeWidth = circleRadius - ((window.scrollY - (sections[0].scrollHeight)) * circleDifference);
        //set percent of scrolled page in circle stoke attributes
        circle.setAttribute('stroke-dashoffset', strokeWidth);

        //get line radius and section height current difference
        const lineDifference = (lineHeight / sections[1].scrollHeight);
        //set percent of scrolled page in line height
        const lineWidth = ((window.scrollY - (sections[0].scrollHeight)) * lineDifference);
        line.style.height = `${lineWidth}px`;

        //if sroke width <= 0, then diacrivate this circle and line - and active next
        if (strokeWidth <= 0) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[2].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[2].querySelector('[data-sections-line-parent]').classList.add('active');
            //if sroke width >=314, then diacrivate this circle and line - and active before
        } else if (strokeWidth >= circleRadius) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[0].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[0].querySelector('[data-sections-line-parent]').classList.add('active');
        } 
    }
    
    //section three
    const sectionThree = () => {
        //elements
        const circleParent = sectionsBlocks[2].querySelector('[data-sections-circe-parent]');
        const lineParent = sectionsBlocks[2].querySelector('[data-sections-line-parent]');
        const circle = sectionsBlocks[2].querySelector('[data-sections-circe]');
        const line = sectionsBlocks[2].querySelector('[data-sections-line]');

        //get circle radius and section height current difference
        const circleDifference = (circleRadius / sections[2].scrollHeight);
        //get stroke width
        const strokeWidth = circleRadius - ((window.scrollY - (sections[0].scrollHeight + sections[1].scrollHeight)) * circleDifference);
        //set percent of scrolled page in circle stoke attributes
        circle.setAttribute('stroke-dashoffset', strokeWidth);

        //get line radius and section height current difference
        const lineDifference = (lineHeight / sections[2].scrollHeight);
        //set percent of scrolled page in line height
        const lineWidth = ((window.scrollY - (sections[0].scrollHeight + sections[1].scrollHeight)) * lineDifference);
        line.style.height = `${lineWidth}px`;

        //if sroke width <= 0, then diacrivate this circle and line - and active next
        if (strokeWidth <= 0) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[3].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[3].querySelector('[data-sections-line-parent]').classList.add('active');
        //if sroke width >=314, then diacrivate this circle and line - and active before
        } else if (strokeWidth >= circleRadius) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[1].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[1].querySelector('[data-sections-line-parent]').classList.add('active');
        } 
    }

    //section fourth
    const sectionFourth = () => {
        //elements
        const circleParent = sectionsBlocks[3].querySelector('[data-sections-circe-parent]');
        const lineParent = sectionsBlocks[3].querySelector('[data-sections-line-parent]');
        const circle = sectionsBlocks[3].querySelector('[data-sections-circe]');
        const line = sectionsBlocks[3].querySelector('[data-sections-line]');

        //get circle radius and section height current difference
        const circleDifference = (circleRadius / sections[3].scrollHeight);
        //get stroke width
        const strokeWidth = circleRadius - ((window.scrollY - (sections[0].scrollHeight + sections[1].scrollHeight + sections[2].scrollHeight)) * circleDifference);
        //set percent of scrolled page in circle stoke attributes
        circle.setAttribute('stroke-dashoffset', strokeWidth);

        //get line radius and section height current difference
        const lineDifference = (lineHeight / sections[3].scrollHeight);
        //set percent of scrolled page in line height
        const lineWidth = ((window.scrollY - (sections[0].scrollHeight + sections[1].scrollHeight + sections[2].scrollHeight)) * lineDifference);
        line.style.height = `${lineWidth}px`;

        //if sroke width <= 0, then diacrivate this circle and line - and active next
        if (strokeWidth <= 0) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[4].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[4].querySelector('[data-sections-line-parent]').classList.add('active');
        //if sroke width >=314, then diacrivate this circle and line - and active before
        } else if (strokeWidth >= circleRadius) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[2].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[2].querySelector('[data-sections-line-parent]').classList.add('active');
        } 
    }

    //section fifth
    const sectionFifth = () => {
        //elements
        const circleParent = sectionsBlocks[4].querySelector('[data-sections-circe-parent]');
        const lineParent = sectionsBlocks[4].querySelector('[data-sections-line-parent]');
        const circle = sectionsBlocks[4].querySelector('[data-sections-circe]');
        const line = sectionsBlocks[4].querySelector('[data-sections-line]');

        //get circle radius and section height current difference
        const circleDifference = (circleRadius / sections[4].scrollHeight);
        //get stroke width
        const strokeWidth = circleRadius - ((window.scrollY - (sections[0].scrollHeight + sections[1].scrollHeight + sections[2].scrollHeight + sections[3].scrollHeight)) * circleDifference);
        //set percent of scrolled page in circle stoke attributes
        circle.setAttribute('stroke-dashoffset', strokeWidth);

        //get line radius and section height current difference
        const lineDifference = (lineHeight / sections[4].scrollHeight);
        //set percent of scrolled page in line height
        const lineWidth = ((window.scrollY - (sections[0].scrollHeight + sections[1].scrollHeight + sections[2].scrollHeight + sections[3].scrollHeight)) * lineDifference);
        line.style.height = `${lineWidth}px`;

        //if sroke width <= 0, then diacrivate this circle and line - and active next
        if (strokeWidth <= 0) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[5].querySelector('[data-sections-circe-parent]').classList.add('active');

        //if sroke width >=314, then diacrivate this circle and line - and active before
        } else if (strokeWidth >= circleRadius) {
            circleParent.classList.remove('active');
            lineParent.classList.remove('active');

            sectionsBlocks[3].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[3].querySelector('[data-sections-line-parent]').classList.add('active');
        } 
    }

    //section sixth
    const sectionSixth = () => {
        //elements
        const circleParent = sectionsBlocks[5].querySelector('[data-sections-circe-parent]');
        const circle = sectionsBlocks[5].querySelector('[data-sections-circe]');

        //get circle radius and section height current difference
        const circleDifference = (circleRadius / sections[5].scrollHeight);
        //get stroke width
        const strokeWidth = circleRadius - ((window.scrollY - (sections[0].scrollHeight + sections[1].scrollHeight + sections[2].scrollHeight + sections[3].scrollHeight+ sections[4].scrollHeight)) * circleDifference);
        //set percent of scrolled page in circle stoke attributes
        circle.setAttribute('stroke-dashoffset', 0);

        //if sroke width >=314, then diacrivate this circle and line - and active before
        if (strokeWidth >= circleRadius) {
            circleParent.classList.remove('active');

            sectionsBlocks[4].querySelector('[data-sections-circe-parent]').classList.add('active');
            sectionsBlocks[4].querySelector('[data-sections-line-parent]').classList.add('active');
        } 
    }

    //scroll logic
    const scrollLogic = () => {
        if (sectionsBlocks[0].querySelector('[data-sections-circe-parent]').classList.contains('active')) {
            sectionOne();
        } else if (sectionsBlocks[1].querySelector('[data-sections-circe-parent]').classList.contains('active')) {
            sectionTwo();
        } else if (sectionsBlocks[2].querySelector('[data-sections-circe-parent]').classList.contains('active')) {
            sectionThree();
        } else if (sectionsBlocks[3].querySelector('[data-sections-circe-parent]').classList.contains('active')) {
            sectionFourth();
        } else if (sectionsBlocks[4].querySelector('[data-sections-circe-parent]').classList.contains('active')) {
            sectionFifth();
        } else if (sectionsBlocks[5].querySelector('[data-sections-circe-parent]').classList.contains('active')) {
            sectionSixth();
        }
    }

    window.addEventListener('scroll', () => {
        scrollLogic();
    });

    scrollLogic();
}

export {sectionsSlider}