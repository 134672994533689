import {headerProgressBar} from './components/headerProgressBar';
import {sectionsSlider} from './components/sectionsSlider';
import {VRGlasses} from './components/mainPage/VRGlasses';
import {videoPlayBtn} from './components/videoPlayBtn';
import {advantagesSlider} from './components/mainPage/advantagesSlider';
import {demonstrationSlider} from './components/mainPage/demonstrationSlider';
import {inputFocusLogic} from './modules/inputFocusLogic';
import {menuModule} from './modules/menuModule';
import {applicationForm} from './components/applicationForm';
import {solutionBlock} from './components/solutionBlock';
import {generalSmooth} from './modules/generalSmooth';

const menuItems = document.querySelectorAll('[data-menu-item]');
const solutionItems = document.querySelectorAll('[data-solutions-item]');
const footerItems = document.querySelectorAll('[data-footer-item]');

//async loading for not important JS files
document.addEventListener("DOMContentLoaded", () => {
    //UI modules
    headerProgressBar();
    sectionsSlider();
    VRGlasses();
    videoPlayBtn();
    advantagesSlider();
    demonstrationSlider();
    inputFocusLogic();
    menuModule();
    applicationForm();
    solutionBlock();
    generalSmooth(menuItems);
    generalSmooth(solutionItems);
    generalSmooth(footerItems);
});