const solutionBlock = () => {

    const solutionProgress = document.getElementById('solutions-progress');
    const solutionItems = document.querySelectorAll('[data-solutions-item]');
    const solutionBottomItems = document.querySelectorAll('[data-solutions-bottom-item]');

    for (let i = 0; i < solutionItems.length; i++) {
        const solutionBlock = solutionItems[i].querySelector('[data-solutions-block]');
        
        //solutions block click logic
        solutionBlock.addEventListener('click', () => {
            //deactivate other items
            for (const sItem of solutionItems) {
                if (sItem.classList.contains('active')) {
                    sItem.classList.remove('active');
                }
            }
            //deactivate other bottom items
            for (const solutionBottomItem of solutionBottomItems) {
                if (solutionBottomItem.classList.contains('active')) {
                    solutionBottomItem.classList.remove('active');
                    // setTimeout(() => {
                        solutionBottomItem.classList.remove('df');
                    // }, 300);
                }
            }
            //activate current item
            solutionItems[i].classList.add('active');
            //activate current bottom item
            // setTimeout(() => {
                solutionBottomItems[i].classList.add('df');
                // setTimeout(() => {
                    solutionBottomItems[i].classList.add('active');
                // }, 1);
            // }, 300);
            //set height for progress bar
            if (solutionItems[i] == solutionItems[0]) {
                solutionProgress.style.height = 165 + 'px';
            } else if (solutionItems[i] == solutionItems[1]) {
                solutionProgress.style.height = 165 * 3 + 'px';
            } else if (solutionItems[i] == solutionItems[2]) {
                solutionProgress.style.height = 165 * 5 + 'px';
            } else if (solutionItems[i] == solutionItems[3]) {
                solutionProgress.style.height = 165 * 7 + 'px';
            } else if (solutionItems[i] == solutionItems[4]) {
                solutionProgress.style.height = 165 * 2 + 'px';
            } else if (solutionItems[i] == solutionItems[5]) {
                solutionProgress.style.height = 165 * 4 + 'px';
            } else if (solutionItems[i] == solutionItems[6]) {
                solutionProgress.style.height = 165 * 6 + 'px';
            } else if (solutionItems[i] == solutionItems[7]) {
                solutionProgress.style.height = 165 * 8 + 'px';
            }
            //scroll to block
            const blockID = solutionBottomItems[i];
            blockID.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        });
    }

}

export {solutionBlock}