const inputFocusLogic = () => {
    const formLabels = document.querySelectorAll('[data-form-label]');
    const formInputs = document.querySelectorAll('[data-form-input]');

    for (let i = 0; i < formInputs.length; i++) {
        //form input focus logic
        formInputs[i].addEventListener('focus', () => {
            formLabels[i].classList.add('focused');
        });

        //form input blur logic
        formInputs[i].addEventListener('blur', () => {
            if (formInputs[i].value == 0) {
                formLabels[i].classList.remove('focused');
            }
        });
    }

}

export {inputFocusLogic}