const videoPlayBtn = () => {

    const playBtns = document.querySelectorAll('[data-video-play-btn]');

    for (const playBtn of playBtns) {
        const video = playBtn.previousElementSibling;

        playBtn.addEventListener('click', () => {
            playBtn.classList.add('dn');
            video.play();
        });

        video.addEventListener('click', () => {
            video.pause();
            playBtn.classList.remove('dn');
        });
    }

}

export {videoPlayBtn}