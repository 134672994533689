const VRGlasses = () => {

    let s;
    const fixedGlassesContainer = document.getElementById('fixed-grasses-container');
    const glassesContainer = document.getElementById('glasses-container');
    const glasses = document.getElementById('glasses');
    const body = document.body;

    // const showGlasses = () => {
    //     body.classList.add('overflow-hidden');
    //     fixedGlassesContainer.classList.remove('hidden');
    // }

    // const hideGlasses = () => {
    //     fixedGlassesContainer.classList.add('hidden');
    //     body.classList.remove('overflow-hidden');
    // }

    // fixedGlassesContainer.addEventListener('scroll', (e) => {
    //     s = 1000 * (fixedGlassesContainer.scrollTop / 100) + 8000;

    //     if (s < 40000) {
    //         showGlasses();

    //         if (s < 20000) {
    //             if (s > 8000) {
    //                 glasses.style.transform = `scale(${(s + 1) / 10000})`;
    //             }
    //         } else {
    //             glassesContainer.style.transform = `translate(-50%, -${((s - 15000) / 100)}%)`;
    //         }
    //     } else {
    //         hideGlasses();
    //         fixedGlassesContainer.scrollTop = 3000;
    //     }
    // });

    // window.addEventListener('scroll', () => {
    //     if (scrollY == 0) {
    //         showGlasses();
    //         fixedGlassesContainer.scrollTop = 3000;
    //     }
    // });

}

export {VRGlasses}