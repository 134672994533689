const menuModule = () => {

    const menu = document.getElementById('menu');
    const menuBtn = document.querySelector('[data-menu-button]');
    const menuBtnText = menuBtn.querySelector('[data-menu-button-text]');
    const sectionsSlider = document.querySelector('[data-sections-slider]');
    const headerProgressBar = document.querySelector('[data-header-progress-bar]');
    const menuItems = document.querySelectorAll('[data-menu-item]');
    const body = document.body;
    //application form buttons
    const applicationFormBtns = document.querySelectorAll('[data-application-form-btn]');

    //menu open logic
    const menuOpenLogic = () => {
        //change data-menu-button attribute to close
        menuBtn.setAttribute('data-menu-button', 'close');
        //change text to close
        menuBtnText.innerHTML = 'CLOSE';
        //hide sections slider and header progress bar
        if (sectionsSlider) {
            sectionsSlider.classList.add('hidden');
        }
        headerProgressBar.classList.add('hidden');
        //hide page scroll (for menu scroll visibilty)
        setTimeout(() => {
            body.classList.add('overflow-hidden');
        }, 500);
        //open menu
        menu.classList.add('df');
        //open menu animation
        setTimeout(() => {
            menu.classList.add('opened');
        }, 1);
    }

    //menu close logic
    const menuCloseLogic = () => {
        //change data-menu-button attribute to open
        menuBtn.setAttribute('data-menu-button', 'open');
        //change text to menu
        menuBtnText.innerHTML = 'MENU';
        //show sections slider and header progress bar
        if (sectionsSlider) {
            sectionsSlider.classList.remove('hidden');
        }
        headerProgressBar.classList.remove('hidden');
        //show page scroll (for page scroll visibilty)
        body.classList.remove('overflow-hidden');
        //close menu animation
        menu.classList.remove('opened');
        //close menu
        setTimeout(() => {
            menu.classList.remove('df');
        }, 500);
    }

    //menu button click logic
    menuBtn.addEventListener('click', () => {
        if (menuBtn.getAttribute('data-menu-button') == 'open') {
            //open menu
            menuOpenLogic();
        } else if (menuBtn.getAttribute('data-menu-button') == 'close') {
            //close menu
            menuCloseLogic();
        }
    });

    for (const menuItem of menuItems) {
        //menu item clikc logic
        menuItem.addEventListener('click', () => {
            if (menu.classList.contains('opened')) {
                //close menu
                menuCloseLogic();
            }
        });
    }

    //application form button logic
    for (const applicationFormBtn of applicationFormBtns) {
        //button click logic
        applicationFormBtn.addEventListener('click', (e) => {
            e.preventDefault();
            if (menuBtn.getAttribute('data-menu-button') == 'close') {
                //close menu
                menuCloseLogic();
            }
            //scroll to block
            const blockID = document.querySelector('[data-application-form]');
            blockID.scrollIntoView({
                behavior: "smooth",
                block: "start"
            });
        });
    }


   
}



export { menuModule }