const generalSmooth = (items) => {
    items.forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector(this.getAttribute('href').substring(1)).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
}

export {generalSmooth}