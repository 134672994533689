import Swiper from 'swiper/bundle';

const demonstrationSlider = () => {

    const videoContainers = document.querySelectorAll('[data-yt-video-container]');

    //slider settings and logic
    const sliderSettings = {
        speed: 500,
        centeredSlides: false,
        loop: true,
        allowTouchMove: false,
        // spaceBetween: 30,
        // initialSlide: 5,

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },

        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
        },

        breakpoints: {
            // when window width is >= 0px
            0: {
                spaceBetween: 10,
            },
            // when window width is >= 768px
            769: {
                spaceBetween: 30,
            },
        }
    }

    const slider = new Swiper('#demonstration-slider', sliderSettings);

    //yt logic
    const ytLogic = () => {
        for (const videoContainer of videoContainers) {
            const videoPoster = videoContainer.querySelector('[data-yt-video-poster]');
            const videoBtn = videoContainer.querySelector('[data-yt-video-play-btn]');

            //video button click logic
            videoBtn.addEventListener('click', () => {
                //hide poster and play button
                videoPoster.classList.add('dn');
                videoBtn.classList.add('dn');
                //take video url from button data attribute
                let videoUrl = videoBtn.getAttribute('data-yt-video-play-btn');
                let videoId = videoBtn.getAttribute('data-yt-video-play-id');
                //create iframe html element and put video inside
                let iframe = `<iframe src="${videoUrl}?autoplay=1&loop=1&playlist=${videoId}" class="swiper-slide__video" frameborder="0" allowfullscreen width="1660" height="930" allow='autoplay' data-yt-iframe></iframe>`
                //put the video in container
                videoContainer.innerHTML += iframe;

                let allIframes = videoContainer.querySelectorAll('[data-yt-iframe]')
                allIframes[1].remove();

                videoUrl = null;
                iframe = null;
                allIframes = null;
            });
    
            //if slider is in active position, then:
            if (videoContainer.classList.contains('swiper-slide-active')) {
                //show play button
                videoBtn.classList.remove('dn');
            } else {
                //hide play button
                videoBtn.classList.add('dn');
            }

            //if video poster is hidden:
            if (videoPoster.classList.contains('dn')) {
                videoPoster.classList.remove('dn');
            }

            //if iframe exist:
            if (videoContainer.querySelector('[data-yt-iframe]')) {
                const iframe = videoContainer.querySelector('[data-yt-iframe]');
                videoContainer.removeChild(iframe);
            }
        }
    }

    const swiperButtons = document.querySelectorAll('[data-yt-swiper-btn]');

    for (const swiperButton of swiperButtons) {
        //swiper button click logic
        swiperButton.addEventListener('click', () => {
            //start yt logic
            ytLogic();
        });
    }

    //initialise yt logic when slider initialise himself
    ytLogic();

}

export {demonstrationSlider}