import Swiper from 'swiper/bundle';

const advantagesSlider = () => {

    //slider settings and logic
    const sliderSettings = {
        speed: 500,
        centeredSlides: false,
        loop: false,
        spaceBetween: 30,
        // slideClass: 'container__item',

        // autoplay: {
        //     delay: 4000,
        //     disableOnInteraction: false
        // },

        breakpoints: {
            // when window width is >= 0px
            0: {
                slidesPerView: 1.1,
            },
            // when window width is >= 799px
            800: {
                slidesPerView: 2,
            },
            // when window width is >= 1199px
            1200: {
                slidesPerView: 3,
            },
            // when window width is >= 1599px
            1600: {
                slidesPerView: 4,
            },
        }
    }

    const slider = new Swiper('#advantages-slider', sliderSettings);

}

export {advantagesSlider}